import { createStyles, makeStyles } from "@material-ui/core/styles";
export var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            flexGrow: 1
        },
        input: {
            marginBottom: theme.spacing(2),
            textAlign: "center",
            color: theme.palette.text.secondary
        }
    });
});
