var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { forwardRef, memo } from "react";
import Card from "@material-ui/core/Card";
import cx from "classnames";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { SessionPageTypeHumanNames } from "@blueheart/conversation-graph/lib/reflections/reflectionQuestions";
export var QuestionCard = memo(forwardRef(function (props, ref) {
    var node = props.node, selectedNodeIndex = props.selectedNodeIndex, setSelectedNodeIndex = props.setSelectedNodeIndex, isDragging = props.isDragging, onAddAfterClick = props.onAddAfterClick, index = props.index, onDeleteClick = props.onDeleteClick, restProps = __rest(props, ["node", "selectedNodeIndex", "setSelectedNodeIndex", "isDragging", "onAddAfterClick", "index", "onDeleteClick"]);
    return (_jsxs(Card, __assign({ classes: {
            root: cx([
                "listNode",
                {
                    listNode__selected: selectedNodeIndex === index,
                    listNode__dragging: isDragging
                }
            ])
        }, ref: ref }, restProps, { onClick: function () {
            setSelectedNodeIndex(index);
        }, elevation: 3 }, { children: [_jsxs(Grid, __assign({ container: true, direction: "row" }, { children: [_jsx(Grid, __assign({ container: true, direction: "column" }, { children: SessionPageTypeHumanNames[node.type] })), _jsx(Grid, __assign({ container: true, direction: "column" }, { children: node.text ||
                            node.question ||
                            node.statement || _jsx("small", { children: "<blank>" }) }))] })), _jsx(Grid, __assign({ container: true, direction: "row" }, { children: _jsxs(ButtonGroup, __assign({ "aria-label": "outlined primary button group" }, { children: [_jsx(Button, __assign({ onClick: function (e) {
                                e.preventDefault();
                                onAddAfterClick(index);
                            }, color: "primary", variant: "contained" }, { children: "Add After" })), _jsx(Button, __assign({ onClick: function (e) {
                                e.preventDefault();
                                onDeleteClick(index);
                            }, color: "secondary", variant: "contained" }, { children: "Delete" }))] })) }))] })));
}));
