export var getNextNumericId = function (nodes) {
    return ((nodes || []).reduce(function (val, node) {
        var asInt = typeof node.id === "string" ? parseInt(node.id, 10) : NaN;
        if (Number.isSafeInteger(asInt)) {
            return Math.max(asInt, val);
        }
        else {
            return val;
        }
    }, 0) + 1);
};
