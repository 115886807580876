var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MessageType } from "@blueheart/conversation-graph/lib/conversationGraph/ConversationGraph";
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Button from "@material-ui/core/Button";
import isNil from "lodash/isNil";
import { getNextNumericId } from "../utils";
import { QuestionCard } from "./QuestionCard";
var createNewDefaultNode = function (conversationGraph, previousNode) {
    return {
        type: MessageType.STATEMENT,
        content: {
            markdown: ""
        },
        id: getNextNumericId(conversationGraph.nodes).toString(),
        section: previousNode === null || previousNode === void 0 ? void 0 : previousNode.section
    };
};
var reorder = function (list, startIndex, endIndex) {
    var result = Array.from(list);
    var removed = result.splice(startIndex, 1)[0];
    result.splice(endIndex, 0, removed);
    return result;
};
var NodesList = /** @class */ (function (_super) {
    __extends(NodesList, _super);
    function NodesList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onDragEnd = function (result) {
            if (!result.destination) {
                return;
            }
            var _a = _this.props, conversationGraph = _a.conversationGraph, setConversationGraph = _a.setConversationGraph, selectedNodeIndex = _a.selectedNodeIndex, setSelectedNodeIndex = _a.setSelectedNodeIndex;
            var nodes = reorder(conversationGraph.nodes, result.source.index, result.destination.index);
            setConversationGraph(__assign(__assign({}, conversationGraph), { nodes: nodes }));
            if (!isNil(selectedNodeIndex)) {
                if (selectedNodeIndex === result.source.index) {
                    // moved selected card
                    setSelectedNodeIndex(result.destination.index);
                }
                else if (result.source.index < selectedNodeIndex &&
                    selectedNodeIndex <= result.destination.index) {
                    // before to after
                    setSelectedNodeIndex(selectedNodeIndex - 1);
                }
                else if (result.destination.index <= selectedNodeIndex &&
                    selectedNodeIndex < result.source.index) {
                    // after to before
                    setSelectedNodeIndex(selectedNodeIndex + 1);
                }
            }
        };
        _this.onAddClick = function () {
            var _a, _b;
            var _c = _this.props, conversationGraph = _c.conversationGraph, setConversationGraph = _c.setConversationGraph;
            setConversationGraph(__assign(__assign({}, conversationGraph), { nodes: __spreadArray(__spreadArray([], (conversationGraph.nodes || []), true), [
                    createNewDefaultNode(conversationGraph, (_a = conversationGraph.nodes) === null || _a === void 0 ? void 0 : _a[((_b = conversationGraph.nodes) === null || _b === void 0 ? void 0 : _b.length) - 1])
                ], false) }));
        };
        _this.onAddAfterClick = function (index) {
            var _a = _this.props, conversationGraph = _a.conversationGraph, setConversationGraph = _a.setConversationGraph;
            setConversationGraph(__assign(__assign({}, conversationGraph), { nodes: __spreadArray(__spreadArray(__spreadArray([], conversationGraph.nodes.slice(0, index + 1), true), [
                    createNewDefaultNode(conversationGraph, conversationGraph.nodes[index])
                ], false), conversationGraph.nodes.slice(index + 1), true) }));
        };
        _this.onDeleteClick = function (index) {
            var _a = _this.props, conversationGraph = _a.conversationGraph, setConversationGraph = _a.setConversationGraph;
            setConversationGraph(__assign(__assign({}, conversationGraph), { nodes: __spreadArray(__spreadArray([], conversationGraph.nodes.slice(0, index), true), conversationGraph.nodes.slice(index + 1), true) }));
        };
        return _this;
    }
    NodesList.prototype.render = function () {
        var _this = this;
        var _a = this.props, conversationGraph = _a.conversationGraph, selectedNodeIndex = _a.selectedNodeIndex, setSelectedNodeIndex = _a.setSelectedNodeIndex;
        return (_jsx(DragDropContext, __assign({ onDragEnd: this.onDragEnd }, { children: _jsx(Droppable, __assign({ droppableId: "droppable" }, { children: function (provided) {
                    var _a;
                    return (_jsxs(Grid, __assign({ classes: { root: "nodes" }, container: true, direction: "column" }, provided.droppableProps, { ref: provided.innerRef }, { children: [(_a = conversationGraph.nodes) === null || _a === void 0 ? void 0 : _a.map(function (item, index) { return (_jsx(Draggable, __assign({ draggableId: item.id, index: index }, { children: function (provided, snapshot) { return (_jsx(QuestionCard, __assign({ node: item, onAddAfterClick: _this.onAddAfterClick, onDeleteClick: _this.onDeleteClick, index: index, selectedNodeIndex: selectedNodeIndex, setSelectedNodeIndex: setSelectedNodeIndex, isDragging: snapshot.isDragging, ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps))); } }), item.id)); }), provided.placeholder, _jsx(Button, __assign({ variant: "contained", color: "primary", onClick: _this.onAddClick }, { children: "Add" }))] })));
                } })) })));
    };
    return NodesList;
}(React.Component));
export { NodesList };
