export function assertNever(x) {
    throw new Error("Unexpected object: " + x);
}
export function compileAssertNever(_) {
    // don't return anything or throw, this is compile-time assert only
}
export function devAssertNever(x, nodeEnv) {
    // don't return anything or throw unless in development
    if (nodeEnv === "development") {
        assertNever(x);
    }
}
export function fallbackNever(_, fallback) {
    // use the type system to check e.g. exhaustiveness of switch/case
    // but have a fallback anyway
    return fallback;
}
