var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import isNil from "lodash/isNil";
import Grid from "@material-ui/core/Grid";
import ReactMarkdown from "react-markdown";
import TextField from "@material-ui/core/TextField";
import * as React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { TableContainer } from "@material-ui/core";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { assertNever } from "@blueheart/utils/src/assertNever";
import { SessionPageType, SessionPageTypeHumanNames } from "@blueheart/conversation-graph/lib/reflections/reflectionQuestions";
import { useStyles } from "../../styles";
var MarkdownField = function (_a) {
    var label = _a.label, value = _a.value, onChange = _a.onChange, className = _a.className;
    return (_jsxs(_Fragment, { children: [_jsx(TextField, { className: className, label: label, variant: "outlined", value: value, multiline: true, required: true, onChange: onChange }), _jsx(Paper, __assign({ className: className }, { children: _jsx(ReactMarkdown, { children: value }) }))] }));
};
var switchNodeType = function (node, type) {
    if (node.type === type) {
        return node;
    }
    var base = {
        header: node.header,
        help: node.help
    };
    switch (type) {
        case SessionPageType.SUMMARY:
            return __assign({ type: SessionPageType.SUMMARY }, base);
        case SessionPageType.SIMPLE:
            return __assign(__assign({ type: SessionPageType.SIMPLE }, base), { text: "text" in node ? node.text : "" });
        case SessionPageType.QUESTION:
            return __assign({ type: SessionPageType.QUESTION, answerId: "answerId" in node ? node.answerId : "", question: "question" in node ? node.question : "", placeholder: "placeholder" in node ? node.placeholder : "", isLast: "isLast" in node ? node.isLast : undefined }, base);
        case SessionPageType.MULTIPLE_CHOICE:
            return __assign({ type: SessionPageType.MULTIPLE_CHOICE, answerId: "answerId" in node ? node.answerId : "", question: "question" in node ? node.question : "", options: "options" in node ? node.options : [], isLast: "isLast" in node ? node.isLast : undefined }, base);
        case SessionPageType.INTERACTIVE:
            return __assign({ type: SessionPageType.INTERACTIVE, answerId: "answerId" in node ? node.answerId : "", statement: "statement" in node ? node.statement : "", explanation: "explanation" in node ? node.explanation : "", isLast: "isLast" in node ? node.isLast : undefined }, base);
        default:
            assertNever(type);
    }
};
export var NodeControls = function (props) {
    var classes = useStyles();
    var reflection = props.reflection, selectedNodeIndex = props.selectedNodeIndex, setReflection = props.setReflection;
    var node = isNil(selectedNodeIndex)
        ? undefined
        : reflection[selectedNodeIndex];
    if (!node || isNil(selectedNodeIndex)) {
        return (_jsx(Grid, __assign({ classes: { root: "nodeControls" }, container: true, direction: "column" }, { children: _jsx("p", { children: "Select a node to edit" }) })));
    }
    var updateNode = function (node) {
        setReflection(__spreadArray(__spreadArray(__spreadArray([], reflection.slice(0, selectedNodeIndex), true), [
            node
        ], false), reflection.slice(selectedNodeIndex + 1), true));
    };
    return (_jsxs("div", __assign({ className: "nodeControls" }, { children: [_jsxs(FormControl, __assign({ className: classes.input }, { children: [_jsx(InputLabel, __assign({ id: "type-select-label" }, { children: "Type" })), _jsx(Select, __assign({ labelId: "type-select-label", id: "type-select", value: node.type, onChange: function (e) {
                            var value = e.target.value;
                            if (typeof value !== "string") {
                                throw new Error("Invalid type of " + value);
                            }
                            var type = value;
                            var newNode = switchNodeType(node, type);
                            updateNode(newNode);
                        } }, { children: Object.values(SessionPageType)
                            .filter(function (type) { return type !== SessionPageType.DUO_CONTROLLED; })
                            .map(function (messageType) {
                            return (_jsx(MenuItem, __assign({ value: messageType }, { children: SessionPageTypeHumanNames[messageType] }), messageType));
                        }) }))] })), _jsx(TypeSpecificControls, __assign({}, props, { node: node, updateNode: updateNode }))] })));
};
var TypeSpecificControls = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var node = props.node, updateNode = props.updateNode;
    var classes = useStyles();
    switch (node.type) {
        case SessionPageType.SUMMARY:
            return null;
        case SessionPageType.SIMPLE:
            return (_jsx(MarkdownField, { className: classes.input, label: "Content", value: (_a = node.text) !== null && _a !== void 0 ? _a : "", onChange: function (e) {
                    var text = e.target.value;
                    updateNode(__assign(__assign({}, node), { text: text }));
                } }));
        case SessionPageType.QUESTION:
            return (_jsxs(_Fragment, { children: [_jsx(MarkdownField, { className: classes.input, label: "Question", value: (_b = node.question) !== null && _b !== void 0 ? _b : "", onChange: function (e) {
                            var question = e.target.value;
                            updateNode(__assign(__assign({}, node), { question: question }));
                        } }), _jsx(TextField, { className: classes.input, label: "Placeholder", variant: "outlined", value: (_c = node.placeholder) !== null && _c !== void 0 ? _c : "", multiline: true, required: true, onChange: function (e) {
                            var placeholder = e.target.value;
                            updateNode(__assign(__assign({}, node), { placeholder: placeholder }));
                        } }), _jsx(TextField, { className: classes.input, label: "Answer Id", variant: "outlined", helperText: "May be used to pull out individual answers", value: (_d = node.answerId) !== null && _d !== void 0 ? _d : "", required: true, onChange: function (e) {
                            var answerId = e.target.value;
                            updateNode(__assign(__assign({}, node), { answerId: answerId }));
                        } })] }));
        case SessionPageType.MULTIPLE_CHOICE:
            return (_jsxs(_Fragment, { children: [_jsx(MarkdownField, { className: classes.input, label: "Question", value: (_e = node.question) !== null && _e !== void 0 ? _e : "", onChange: function (e) {
                            var question = e.target.value;
                            updateNode(__assign(__assign({}, node), { question: question }));
                        } }), _jsx(TextField, { className: classes.input, label: "Answer Id", variant: "outlined", value: (_f = node.answerId) !== null && _f !== void 0 ? _f : "", required: true, onChange: function (e) {
                            var answerId = e.target.value;
                            updateNode(__assign(__assign({}, node), { answerId: answerId }));
                        } }), _jsx(TextChoiceCreator, { options: node.options, onOptionsChange: function (options) { return updateNode(__assign(__assign({}, node), { options: options })); } })] }));
        case SessionPageType.INTERACTIVE:
            return (_jsxs(_Fragment, { children: [_jsx(TextField, { className: classes.input, label: "Statement", variant: "outlined", value: (_g = node.statement) !== null && _g !== void 0 ? _g : "", helperText: 'Needs to contain blanks using curly braces, with the text inside being used to identify the blank should we ever want to use them, e.g. "I feel {feelings} when you get mad at me"', multiline: true, required: true, onChange: function (e) {
                            var statement = e.target.value;
                            updateNode(__assign(__assign({}, node), { statement: statement }));
                        } }), _jsx(MarkdownField, { className: classes.input, label: "Explanation", value: (_h = node.explanation) !== null && _h !== void 0 ? _h : "", onChange: function (e) {
                            var explanation = e.target.value;
                            updateNode(__assign(__assign({}, node), { explanation: explanation }));
                        } }), _jsx(TextField, { className: classes.input, label: "Answer Id", variant: "outlined", value: (_j = node.answerId) !== null && _j !== void 0 ? _j : "", helperText: "May be used to pull out individual answers", required: true, onChange: function (e) {
                            var answerId = e.target.value;
                            updateNode(__assign(__assign({}, node), { answerId: answerId }));
                        } })] }));
        default:
            assertNever(node);
    }
};
var TextChoiceCreator = /** @class */ (function (_super) {
    __extends(TextChoiceCreator, _super);
    function TextChoiceCreator() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onDragEnd = function (result) {
            if (!result.destination) {
                console.log("no destiny", result);
                return;
            }
            var _a = _this.props, options = _a.options, onOptionsChange = _a.onOptionsChange;
            var newOptions = Array.from(options);
            var removed = newOptions.splice(result.source.index, 1)[0];
            newOptions.splice(result.destination.index, 0, removed);
            onOptionsChange(newOptions);
        };
        return _this;
    }
    TextChoiceCreator.prototype.render = function () {
        var _a = this.props, options = _a.options, onOptionsChange = _a.onOptionsChange;
        return (_jsxs(_Fragment, { children: [_jsx(DragDropContext, __assign({ onDragEnd: this.onDragEnd }, { children: _jsx(Droppable, __assign({ droppableId: "text-choice" }, { children: function (provided) { return (_jsxs(TableContainer, __assign({ component: Paper }, provided.droppableProps, { ref: provided.innerRef, classes: {
                                root: "optionTable"
                            } }, { children: [_jsx(Table, { children: _jsx("tbody", { children: (options.length === 0 ? [""] : options).map(function (item, index) {
                                            return (_jsx(Draggable, __assign({ draggableId: "".concat(index), index: index }, { children: function (provided) { return (_jsxs(TableRow, __assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { children: [_jsx(TableCell, { children: _jsx(TextField, { label: "Option", variant: "outlined", value: item, required: true, onChange: function (e) {
                                                                    onOptionsChange(__spreadArray(__spreadArray(__spreadArray([], options.slice(0, index), true), [
                                                                        e.currentTarget.value
                                                                    ], false), options.slice(index + 1), true));
                                                                } }) }), _jsx(TableCell, { children: _jsx(Button, __assign({ onClick: function () {
                                                                    onOptionsChange(__spreadArray(__spreadArray([], options.slice(0, index), true), options.slice(index + 1), true));
                                                                } }, { children: "Delete" })) })] }))); } }), index));
                                        }) }) }), provided.placeholder] }))); } })) })), _jsx(Button, __assign({ variant: "contained", color: "primary", onClick: function () { return onOptionsChange(__spreadArray(__spreadArray([], options, true), [""], false)); } }, { children: "Add Options" }))] }));
    };
    return TextChoiceCreator;
}(React.Component));
