var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { HealthScore, HealthTextResponse, MessageType, SideEffect } from "@blueheart/conversation-graph/lib/conversationGraph/ConversationGraph";
import isNil from "lodash/isNil";
import Grid from "@material-ui/core/Grid";
import ReactMarkdown from "react-markdown";
import TextField from "@material-ui/core/TextField";
import * as React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Checkbox, TableContainer } from "@material-ui/core";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { isNotNil } from "@blueheart/utils/src/isNotNil";
import { assertNever } from "@blueheart/utils/src/assertNever";
import { getNextNumericId } from "../utils";
import { useStyles } from "../../styles";
import { LogicSection } from "./LogicSection";
var getTextChoiceOptionsFromOtherMessageType = function (node) {
    switch (node.type) {
        case MessageType.SINGLE_SELECT:
        case MessageType.MULTIPLE_SELECT:
        case MessageType.ORDERING:
            return node.options;
        case MessageType.SINGLE_SELECT_IMAGE:
        case MessageType.MULTIPLE_SELECT_IMAGE:
        case MessageType.ORDERING_IMAGE:
            return node.options.map(function (option) { return ({
                value: option.value,
                label: option.label
            }); });
        default:
            return [];
    }
};
var getImageChoiceOptionsFromOtherMessageType = function (node) {
    switch (node.type) {
        case MessageType.SINGLE_SELECT:
        case MessageType.MULTIPLE_SELECT:
        case MessageType.ORDERING:
            return node.options.map(function (option) { return ({
                label: option.label,
                value: option.value,
                imageId: ""
            }); });
        case MessageType.SINGLE_SELECT_IMAGE:
        case MessageType.MULTIPLE_SELECT_IMAGE:
        case MessageType.ORDERING_IMAGE:
            return node.options;
        default:
            return [];
    }
};
var switchNodeType = function (node, type) {
    if (node.type === type) {
        return node;
    }
    var base = {
        id: node.id,
        key: node.key,
        section: node.section,
        logic: node.logic,
        content: node.content
    };
    switch (type) {
        case MessageType.STATEMENT: {
            return __assign(__assign({ type: MessageType.STATEMENT }, base), { preventContinue: "preventContinue" in node ? node.preventContinue : undefined, imageId: "imageId" in node ? node.imageId : undefined });
        }
        case MessageType.SHORT_TEXT: {
            return __assign(__assign({ type: MessageType.SHORT_TEXT }, base), { placeholder: "placeholder" in node ? node.placeholder : "", isOptional: "isOptional" in node ? node.isOptional : false });
        }
        case MessageType.LONG_TEXT: {
            return __assign(__assign({ type: MessageType.LONG_TEXT }, base), { placeholder: "placeholder" in node ? node.placeholder : "", isOptional: "isOptional" in node ? node.isOptional : false });
        }
        case MessageType.NUMBER: {
            return __assign(__assign({ type: MessageType.NUMBER }, base), { numericOptions: "numericOptions" in node ? node.numericOptions : undefined });
        }
        case MessageType.YES_NO: {
            return __assign({ type: MessageType.YES_NO }, base);
        }
        case MessageType.EMAIL: {
            return __assign(__assign({ type: MessageType.EMAIL }, base), { placeholder: "placeholder" in node ? node.placeholder : "" });
        }
        case MessageType.PHONE_NUMBER: {
            return __assign(__assign({ type: MessageType.PHONE_NUMBER }, base), { placeholder: "placeholder" in node ? node.placeholder : "" });
        }
        case MessageType.SINGLE_SELECT: {
            return __assign(__assign({ type: MessageType.SINGLE_SELECT }, base), { options: getTextChoiceOptionsFromOtherMessageType(node), isWriteInEnabled: "isWriteInEnabled" in node ? node.isWriteInEnabled : undefined, writeInMessage: "writeInMessage" in node ? node.writeInMessage : undefined });
        }
        case MessageType.MULTIPLE_SELECT: {
            return __assign(__assign({ type: MessageType.MULTIPLE_SELECT }, base), { options: getTextChoiceOptionsFromOtherMessageType(node), isWriteInEnabled: "isWriteInEnabled" in node ? node.isWriteInEnabled : undefined, writeInMessage: "writeInMessage" in node ? node.writeInMessage : undefined, isWriteInMultipleEnabled: "isWriteInMultipleEnabled" in node
                    ? node.isWriteInMultipleEnabled
                    : undefined, allowNone: "allowNone" in node ? node.allowNone : undefined });
        }
        case MessageType.ORDERING: {
            return __assign(__assign({ type: MessageType.ORDERING }, base), { options: getTextChoiceOptionsFromOtherMessageType(node), optionsFromSession: "optionsFromSession" in node ? node.optionsFromSession : undefined });
        }
        case MessageType.SINGLE_SELECT_IMAGE: {
            return __assign(__assign({ type: MessageType.SINGLE_SELECT_IMAGE }, base), { options: getImageChoiceOptionsFromOtherMessageType(node) });
        }
        case MessageType.MULTIPLE_SELECT_IMAGE: {
            return __assign(__assign({ type: MessageType.MULTIPLE_SELECT_IMAGE }, base), { options: getImageChoiceOptionsFromOtherMessageType(node) });
        }
        case MessageType.ORDERING_IMAGE: {
            return __assign(__assign({ type: MessageType.ORDERING_IMAGE }, base), { options: getImageChoiceOptionsFromOtherMessageType(node) });
        }
        case MessageType.LIKERT: {
            return __assign(__assign({ type: MessageType.LIKERT }, base), { likertOptions: "likertOptions" in node
                    ? node.likertOptions
                    : {
                        min: 1,
                        max: 5,
                        step: 1
                    } });
        }
        case MessageType.ANIMATION: {
            return __assign(__assign({ type: MessageType.ANIMATION }, base), { animationName: "" });
        }
        case MessageType.SKIP: {
            return __assign({ type: MessageType.SKIP }, base);
        }
        case MessageType.END: {
            return __assign({ type: MessageType.END }, base);
        }
        default:
            assertNever(type);
    }
};
export var NodeControls = function (props) {
    var _a, _b;
    var classes = useStyles();
    var conversationGraph = props.conversationGraph, selectedNodeIndex = props.selectedNodeIndex, setConversationGraph = props.setConversationGraph;
    var node = isNil(selectedNodeIndex)
        ? undefined
        : conversationGraph.nodes[selectedNodeIndex];
    if (!node || isNil(selectedNodeIndex)) {
        return (_jsx(Grid, __assign({ classes: { root: "nodeControls" }, container: true, direction: "column" }, { children: _jsx("span", { children: "Select a node to edit" }) })));
    }
    var updateNode = function (node) {
        setConversationGraph(__assign(__assign({}, conversationGraph), { nodes: __spreadArray(__spreadArray(__spreadArray([], conversationGraph.nodes.slice(0, selectedNodeIndex), true), [
                node
            ], false), conversationGraph.nodes.slice(selectedNodeIndex + 1), true) }));
    };
    return (_jsxs("div", __assign({ className: "nodeControls" }, { children: [_jsxs(Paper, __assign({ classes: { root: "controlsSection" } }, { children: [_jsx("h2", { children: "Basic Controls" }), _jsx(TextField, { className: classes.input, label: "Id (must be unique)", variant: "outlined", value: node.id, required: true, onChange: function (e) {
                            var id = e.target.value;
                            updateNode(__assign(__assign({}, node), { id: id }));
                        } }), _jsx(TextField, { className: classes.input, label: "Section name", variant: "outlined", value: node.section || "", required: true, onChange: function (e) {
                            var section = e.target.value;
                            updateNode(__assign(__assign({}, node), { section: section }));
                        } }), _jsx(TextField, { className: classes.input, label: "Key (must be unique), to identify data to pull out", variant: "outlined", value: node.key || "", required: true, onChange: function (e) {
                            var key = e.target.value;
                            updateNode(__assign(__assign({}, node), { key: key }));
                        } }), _jsx(TextField, { className: classes.input, label: "Content", variant: "outlined", value: ((_a = node.content) === null || _a === void 0 ? void 0 : _a.markdown) || "", multiline: true, required: true, onChange: function (e) {
                            var markdown = e.target.value;
                            updateNode(__assign(__assign({}, node), { content: {
                                    markdown: markdown
                                } }));
                        } }), _jsx(Paper, __assign({ className: classes.input }, { children: _jsx(ReactMarkdown, { children: ((_b = node.content) === null || _b === void 0 ? void 0 : _b.markdown) || "" }) })), _jsxs(FormControl, __assign({ className: classes.input }, { children: [_jsx(InputLabel, __assign({ id: "type-select-label" }, { children: "Type" })), _jsx(Select, __assign({ labelId: "type-select-label", id: "type-select", value: node.type, onChange: function (e) {
                                    var value = e.target.value;
                                    if (typeof value !== "string") {
                                        throw new Error("Invalid type of " + value);
                                    }
                                    var type = value;
                                    var newNode = switchNodeType(node, type);
                                    updateNode(newNode);
                                } }, { children: Object.values(MessageType).map(function (messageType) {
                                    return (_jsx(MenuItem, __assign({ value: messageType }, { children: messageType }), messageType));
                                }) }))] }))] })), _jsxs(Paper, __assign({ classes: { root: "controlsSection" } }, { children: [_jsx("h2", { children: "Type Specific Controls" }), _jsx(TypeSpecificControls, __assign({}, props, { node: node, updateNode: updateNode }))] })), _jsx(LogicSection, { logic: node.logic, updateLogic: function (logic) {
                    return updateNode(__assign(__assign({}, node), { logic: logic }));
                } })] })));
};
var HealthScoreControls = function (_a) {
    var _b, _c;
    var node = _a.node, updateNode = _a.updateNode;
    var classes = useStyles();
    return (_jsxs(_Fragment, { children: [_jsx("h3", { children: "Health Score Calculations" }), _jsxs(FormControl, __assign({ className: classes.input }, { children: [_jsx(InputLabel, __assign({ id: "health-factor-select-label" }, { children: "Factor for which Health Score?" })), _jsx(Select, __assign({ labelId: "health-factor-select-label", id: "health-factor-select", value: ((_b = node.healthScoreFactor) === null || _b === void 0 ? void 0 : _b.healthScore) || "", onChange: function (e) {
                            var value = e.target.value;
                            if (value !== undefined && typeof value !== "string") {
                                throw new Error("Invalid type of " + value);
                            }
                            var existingFactor = node.healthScoreFactor;
                            var newHealthScore = value;
                            updateNode(__assign(__assign({}, node), { healthScoreFactor: newHealthScore && __assign(__assign({}, existingFactor), { healthScore: newHealthScore }) }));
                        } }, { children: Object.values(HealthScore).map(function (healthScore) {
                            return (_jsx(MenuItem, __assign({ value: healthScore }, { children: healthScore }), healthScore));
                        }) }))] })), _jsx(FormControlLabel, { className: classes.input, control: _jsx(Checkbox, { checked: !!((_c = node.healthScoreFactor) === null || _c === void 0 ? void 0 : _c.invertScale), onChange: function (e) {
                        return updateNode(__assign(__assign({}, node), { healthScoreFactor: node.healthScoreFactor && __assign(__assign({}, node.healthScoreFactor), { invertScale: e.target.checked }) }));
                    }, name: "healthScoreInvert" }), label: "Invert scale in calculations" })] }));
};
var HealthTextResponseControls = function (_a) {
    var node = _a.node, updateNode = _a.updateNode;
    var classes = useStyles();
    return (_jsxs(_Fragment, { children: [_jsx("h3", { children: "Health Score Calculations" }), _jsxs(FormControl, __assign({ className: classes.input }, { children: [_jsx(InputLabel, __assign({ id: "health-text-select-label" }, { children: "Store text under what key?" })), _jsx(Select, __assign({ labelId: "health-text-select-label", id: "health-text-select", value: node.healthTextResponse, onChange: function (e) {
                            var value = e.target.value;
                            if (value !== undefined && typeof value !== "string") {
                                throw new Error("Invalid type of " + value);
                            }
                            updateNode(__assign(__assign({}, node), { healthTextResponse: value }));
                        } }, { children: Object.values(HealthTextResponse).map(function (textResponse) {
                            return (_jsx(MenuItem, __assign({ value: textResponse }, { children: textResponse }), textResponse));
                        }) }))] }))] }));
};
var TypeSpecificControls = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var node = props.node, updateNode = props.updateNode;
    var classes = useStyles();
    switch (node.type) {
        case MessageType.STATEMENT:
            return (_jsxs(_Fragment, { children: [_jsx(FormControlLabel, { className: classes.input, control: _jsx(Checkbox, { checked: !!node.preventContinue, onChange: function (e) {
                                return updateNode(__assign(__assign({}, node), { preventContinue: e.target.checked }));
                            }, name: "prevent-continue" }), label: "Prevent Continue" }), _jsx(FormControlLabel, { className: classes.input, control: _jsx(Checkbox, { checked: isNotNil(node.imageId), onChange: function (e) {
                                return updateNode(__assign(__assign({}, node), { imageId: e.target.checked ? "" : undefined }));
                            } }), label: "Has Image?" }), isNotNil(node.imageId) && (_jsx(TextField, { className: classes.input, label: "Image Id", variant: "outlined", value: node.imageId, required: true, onChange: function (e) {
                            updateNode(__assign(__assign({}, node), { imageId: e.target.value }));
                        } }))] }));
        case MessageType.YES_NO:
            return _jsx("div", { children: "There are no controls for this one ;)" });
        case MessageType.SHORT_TEXT:
        case MessageType.LONG_TEXT:
            return (_jsxs(_Fragment, { children: [_jsx(TextField, { className: classes.input, label: "Placeholder", variant: "outlined", value: node.placeholder, required: true, onChange: function (e) {
                            updateNode(__assign(__assign({}, node), { placeholder: e.target.value }));
                        } }), _jsx(FormControlLabel, { className: classes.input, control: _jsx(Checkbox, { checked: node.isOptional, onChange: function (e) {
                                return updateNode(__assign(__assign({}, node), { isOptional: e.target.checked }));
                            }, name: "is-optional" }), label: "Is Optional" })] }));
        case MessageType.NUMBER: {
            var hasMinimum = !!(node.numericOptions && !isNil(node.numericOptions.min));
            var hasMaximum = !!(node.numericOptions && !isNil(node.numericOptions.max));
            var min = (_a = node.numericOptions) === null || _a === void 0 ? void 0 : _a.min;
            var max = (_b = node.numericOptions) === null || _b === void 0 ? void 0 : _b.min;
            return (_jsxs("div", { children: [_jsx(FormControlLabel, { className: classes.input, control: _jsx(Checkbox, { checked: hasMinimum, onChange: function (e) {
                                return updateNode(__assign(__assign({}, node), { numericOptions: __assign(__assign({}, (node.numericOptions || {})), { min: e.currentTarget.checked ? -Infinity : undefined }) }));
                            }, name: "has-minimum" }), label: "Has Minimum?" }), _jsx(FormControlLabel, { className: classes.input, control: _jsx(Checkbox, { checked: hasMaximum, onChange: function (e) {
                                return updateNode(__assign(__assign({}, node), { numericOptions: __assign(__assign({}, (node.numericOptions || {})), { max: e.currentTarget.checked ? Infinity : undefined }) }));
                            }, name: "has-maximum" }), label: "Has Maximum?" }), hasMinimum && (_jsxs("div", { children: [_jsx(TextField, { id: "minimum-value", label: "Minimum", type: "number", variant: "outlined", value: typeof min === "number" && !isNaN(min) ? min : "", className: classes.input, onChange: function (e) {
                                    updateNode(__assign(__assign({}, node), { numericOptions: __assign(__assign({}, (node.numericOptions || {})), { min: parseFloat(e.currentTarget.value) }) }));
                                } }), _jsx(TextField, { className: classes.input, label: "Min Message", variant: "outlined", value: ((_c = node.numericOptions) === null || _c === void 0 ? void 0 : _c.minMessage) || "", onChange: function (e) {
                                    updateNode(__assign(__assign({}, node), { numericOptions: __assign(__assign({}, (node.numericOptions || {})), { minMessage: e.target.value }) }));
                                } })] })), hasMaximum && (_jsxs("div", { children: [_jsx(TextField, { id: "maximum-value", label: "Maximum", type: "number", variant: "outlined", value: typeof max === "number" && !isNaN(max) ? max : "", className: classes.input, onChange: function (e) {
                                    updateNode(__assign(__assign({}, node), { numericOptions: __assign(__assign({}, (node.numericOptions || {})), { max: parseFloat(e.currentTarget.value) }) }));
                                } }), _jsx(TextField, { className: classes.input, label: "Max Message", variant: "outlined", value: ((_d = node.numericOptions) === null || _d === void 0 ? void 0 : _d.maxMessage) || "", onChange: function (e) {
                                    updateNode(__assign(__assign({}, node), { numericOptions: __assign(__assign({}, (node.numericOptions || {})), { maxMessage: e.target.value }) }));
                                } })] })), _jsx(HealthScoreControls, { node: node, updateNode: updateNode })] }));
        }
        case MessageType.EMAIL:
            return (_jsx(TextField, { className: classes.input, label: "Placeholder", variant: "outlined", value: node.placeholder, required: true, onChange: function (e) {
                    updateNode(__assign(__assign({}, node), { placeholder: e.target.value }));
                } }));
        case MessageType.PHONE_NUMBER:
            return (_jsx(TextField, { className: classes.input, label: "Placeholder", variant: "outlined", value: node.placeholder, required: true, onChange: function (e) {
                    updateNode(__assign(__assign({}, node), { placeholder: e.target.value }));
                } }));
        case MessageType.SINGLE_SELECT:
        case MessageType.MULTIPLE_SELECT:
        case MessageType.ORDERING: {
            return (_jsxs(_Fragment, { children: [node.type === MessageType.MULTIPLE_SELECT && (_jsx(FormControlLabel, { className: classes.input, control: _jsx(Checkbox, { checked: !!node.allowNone, onChange: function (e) {
                                return updateNode(__assign(__assign({}, node), { allowNone: e.target.checked }));
                            } }), label: 'Allow "None of the above" answer?' })), (node.type === MessageType.SINGLE_SELECT ||
                        node.type === MessageType.MULTIPLE_SELECT) && (_jsxs(_Fragment, { children: [_jsx(FormControlLabel, { className: classes.input, control: _jsx(Checkbox, { checked: !!node.isWriteInEnabled, onChange: function (e) {
                                        return updateNode(__assign(__assign({}, node), { isWriteInEnabled: e.currentTarget.checked }));
                                    } }), label: "Is Write-In Enabled?" }), node.isWriteInEnabled && (_jsx(TextField, { className: classes.input, label: "Write-In Message", variant: "outlined", value: node.writeInMessage || "", required: true, onChange: function (e) {
                                    updateNode(__assign(__assign({}, node), { writeInMessage: e.target.value }));
                                } }))] })), node.type === MessageType.MULTIPLE_SELECT &&
                        node.isWriteInEnabled && (_jsx(FormControlLabel, { className: classes.input, control: _jsx(Checkbox, { checked: !!node.isWriteInMultipleEnabled, onChange: function (e) {
                                return updateNode(__assign(__assign({}, node), { isWriteInMultipleEnabled: e.currentTarget.checked }));
                            } }), label: "Is Write-In Multiple Enabled?" })), _jsx(TextChoiceCreator, { options: node.options, onOptionsChange: function (options) { return updateNode(__assign(__assign({}, node), { options: options })); } }), node.type === MessageType.MULTIPLE_SELECT && (_jsx(HealthTextResponseControls, { node: node, updateNode: updateNode }))] }));
        }
        case MessageType.SINGLE_SELECT_IMAGE:
        case MessageType.MULTIPLE_SELECT_IMAGE:
        case MessageType.ORDERING_IMAGE: {
            return (_jsx(ImageChoiceCreator, { options: node.options, onOptionsChange: function (options) { return updateNode(__assign(__assign({}, node), { options: options })); } }));
        }
        case MessageType.LIKERT:
            return (_jsxs(_Fragment, { children: [_jsx(TextField, { id: "minimum-value", label: "Minimum", type: "number", variant: "outlined", value: node.likertOptions.min, className: classes.input, onChange: function (e) {
                            updateNode(__assign(__assign({}, node), { likertOptions: __assign(__assign({}, node.likertOptions), { min: parseInt(e.currentTarget.value) }) }));
                        } }), _jsx(TextField, { className: classes.input, label: "Min Label", variant: "outlined", value: ((_e = node.likertOptions) === null || _e === void 0 ? void 0 : _e.minLabel) || "", onChange: function (e) {
                            updateNode(__assign(__assign({}, node), { likertOptions: __assign(__assign({}, node.likertOptions), { minLabel: e.target.value }) }));
                        } }), _jsx(TextField, { id: "maximum-value", label: "Maximum", type: "number", variant: "outlined", value: node.likertOptions.max, className: classes.input, onChange: function (e) {
                            updateNode(__assign(__assign({}, node), { likertOptions: __assign(__assign({}, node.likertOptions), { max: parseInt(e.currentTarget.value) }) }));
                        } }), _jsx(TextField, { className: classes.input, label: "Max Label", variant: "outlined", value: ((_f = node.likertOptions) === null || _f === void 0 ? void 0 : _f.maxLabel) || "", onChange: function (e) {
                            updateNode(__assign(__assign({}, node), { likertOptions: __assign(__assign({}, node.likertOptions), { maxLabel: e.target.value }) }));
                        } }), _jsx(TextField, { className: classes.input, label: "Mid Label", variant: "outlined", value: ((_g = node.likertOptions) === null || _g === void 0 ? void 0 : _g.midLabel) || "", onChange: function (e) {
                            updateNode(__assign(__assign({}, node), { likertOptions: __assign(__assign({}, node.likertOptions), { midLabel: e.target.value }) }));
                        } }), _jsx(HealthScoreControls, { node: node, updateNode: updateNode })] }));
        case MessageType.SKIP:
            return _jsx("p", { children: "No options" });
        case MessageType.END:
            return _jsx(SideEffectCreator, { node: node, updateNode: updateNode });
        case MessageType.ANIMATION:
            return (_jsxs(_Fragment, { children: [_jsx(TextField, { className: classes.input, label: "Animation name", variant: "outlined", value: node.animationName, onChange: function (e) {
                            updateNode(__assign(__assign({}, node), { animationName: e.target.value }));
                        } }), _jsx(SideEffectCreator, { node: node, updateNode: updateNode })] }));
        default:
            assertNever(node);
    }
};
var SideEffectCreator = function (_a) {
    var node = _a.node, updateNode = _a.updateNode;
    var classes = useStyles();
    return (_jsxs(FormControl, __assign({ className: classes.input }, { children: [_jsx(InputLabel, __assign({ id: "side-effect-select-label" }, { children: "Side Effect" })), _jsxs(Select, __assign({ labelId: "side-effect-select-label", id: "side-effect-select", value: node.sideEffect, onChange: function (e) {
                    var value = e.target.value;
                    if (value && typeof value !== "string") {
                        throw new Error("Invalid type of " + value);
                    }
                    var sideEffect = value;
                    updateNode(__assign(__assign({}, node), { sideEffect: sideEffect }));
                } }, { children: [_jsx(MenuItem, __assign({ value: undefined }, { children: "None" }), "null"), Object.values(SideEffect).map(function (sideEffect) {
                        return (_jsx(MenuItem, __assign({ value: sideEffect }, { children: sideEffect }), sideEffect));
                    })] }))] })));
};
var TextChoiceCreator = /** @class */ (function (_super) {
    __extends(TextChoiceCreator, _super);
    function TextChoiceCreator() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onDragEnd = function (result) {
            if (!result.destination) {
                return;
            }
            var _a = _this.props, options = _a.options, onOptionsChange = _a.onOptionsChange;
            var newOptions = Array.from(options);
            var removed = newOptions.splice(result.source.index, 1)[0];
            newOptions.splice(result.destination.index, 0, removed);
            onOptionsChange(newOptions);
        };
        return _this;
    }
    TextChoiceCreator.prototype.render = function () {
        var _a = this.props, options = _a.options, onOptionsChange = _a.onOptionsChange;
        return (_jsxs(_Fragment, { children: [_jsx(DragDropContext, __assign({ onDragEnd: this.onDragEnd }, { children: _jsx(Droppable, __assign({ droppableId: "text-choice" }, { children: function (provided) { return (_jsxs(TableContainer, __assign({ component: Paper }, provided.droppableProps, { ref: provided.innerRef, classes: {
                                root: "optionTable"
                            } }, { children: [_jsx(Table, { children: _jsx("tbody", { children: options.map(function (item, index) {
                                            var id = item.id || item.label + "_________" + item.value;
                                            return (_jsx(Draggable, __assign({ draggableId: id, index: index }, { children: function (provided) { return (_jsxs(TableRow, __assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { children: [_jsx(TableCell, { children: _jsx(TextField, { label: "Label", variant: "outlined", value: item.label, required: true, onChange: function (e) {
                                                                    onOptionsChange(__spreadArray(__spreadArray(__spreadArray([], options.slice(0, index), true), [
                                                                        __assign(__assign({}, item), { label: e.currentTarget.value })
                                                                    ], false), options.slice(index + 1), true));
                                                                } }) }), _jsx(TableCell, { children: _jsx(TextField, { label: "Value", variant: "outlined", value: item.value, required: true, onChange: function (e) {
                                                                    onOptionsChange(__spreadArray(__spreadArray(__spreadArray([], options.slice(0, index), true), [
                                                                        __assign(__assign({}, item), { value: e.currentTarget.value })
                                                                    ], false), options.slice(index + 1), true));
                                                                } }) }), _jsx(TableCell, { children: _jsx(Button, __assign({ onClick: function () {
                                                                    onOptionsChange(__spreadArray(__spreadArray([], options.slice(0, index), true), options.slice(index + 1), true));
                                                                } }, { children: "Delete" })) })] }))); } }), id));
                                        }) }) }), provided.placeholder] }))); } })) })), _jsx(Button, __assign({ variant: "contained", color: "primary", onClick: function () {
                        return onOptionsChange(__spreadArray(__spreadArray([], options, true), [
                            {
                                label: "",
                                value: "",
                                id: getNextNumericId(options).toString()
                            }
                        ], false));
                    } }, { children: "Add Options" }))] }));
    };
    return TextChoiceCreator;
}(React.Component));
var ImageChoiceCreator = /** @class */ (function (_super) {
    __extends(ImageChoiceCreator, _super);
    function ImageChoiceCreator() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onDragEnd = function (result) {
            if (!result.destination) {
                return;
            }
            var _a = _this.props, options = _a.options, onOptionsChange = _a.onOptionsChange;
            var newOptions = Array.from(options);
            var removed = newOptions.splice(result.source.index, 1)[0];
            newOptions.splice(result.destination.index, 0, removed);
            onOptionsChange(newOptions);
        };
        return _this;
    }
    ImageChoiceCreator.prototype.render = function () {
        var _a = this.props, options = _a.options, onOptionsChange = _a.onOptionsChange;
        return (_jsxs(_Fragment, { children: [_jsx(DragDropContext, __assign({ onDragEnd: this.onDragEnd }, { children: _jsx(Droppable, __assign({ droppableId: "image-choices" }, { children: function (provided) { return (_jsxs(TableContainer, __assign({ component: Paper }, provided.droppableProps, { ref: provided.innerRef, classes: {
                                root: "optionTable"
                            } }, { children: [_jsx(Table, { children: options.map(function (item, index) {
                                        var id = item.id || item.label + "_________" + item.value;
                                        return (_jsx(Draggable, __assign({ draggableId: id, index: index }, { children: function (provided) { return (_jsxs(TableRow, __assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { children: [_jsx(TableCell, { children: _jsx(TextField, { label: "Label", variant: "outlined", value: item.label, required: true, onChange: function (e) {
                                                                onOptionsChange(__spreadArray(__spreadArray(__spreadArray([], options.slice(0, index), true), [
                                                                    __assign(__assign({}, item), { label: e.currentTarget.value })
                                                                ], false), options.slice(index + 1), true));
                                                            } }) }), _jsx(TableCell, { children: _jsx(TextField, { label: "Value", variant: "outlined", value: item.value, required: true, onChange: function (e) {
                                                                onOptionsChange(__spreadArray(__spreadArray(__spreadArray([], options.slice(0, index), true), [
                                                                    __assign(__assign({}, item), { value: e.currentTarget.value })
                                                                ], false), options.slice(index + 1), true));
                                                            } }) }), _jsx(TableCell, { children: _jsx(TextField, { label: "Image Id", variant: "outlined", value: item.imageId, required: true, onChange: function (e) {
                                                                onOptionsChange(__spreadArray(__spreadArray(__spreadArray([], options.slice(0, index), true), [
                                                                    __assign(__assign({}, item), { imageId: e.currentTarget.value })
                                                                ], false), options.slice(index + 1), true));
                                                            } }) }), _jsx(TableCell, { children: _jsx(Button, __assign({ onClick: function () {
                                                                onOptionsChange(__spreadArray(__spreadArray([], options.slice(0, index), true), options.slice(index + 1), true));
                                                            } }, { children: "Delete" })) })] }))); } }), id));
                                    }) }), provided.placeholder] }))); } })) })), _jsx(Button, __assign({ variant: "contained", color: "primary", onClick: function () {
                        return onOptionsChange(__spreadArray(__spreadArray([], options, true), [
                            {
                                label: "",
                                value: "",
                                imageId: "",
                                id: getNextNumericId(options).toString()
                            }
                        ], false));
                    } }, { children: "Add Options" }))] }));
    };
    return ImageChoiceCreator;
}(React.Component));
