var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BooleanReduceFn, Comparison } from "@blueheart/conversation-graph/lib/conversationGraph/ConversationGraph";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Checkbox } from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import isNil from "lodash/isNil";
import ToggleButton from "@material-ui/lab/ToggleButton";
import TextField from "@material-ui/core/TextField";
import * as React from "react";
import { forwardRef } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { isNotNil } from "@blueheart/utils/src/isNotNil";
import { useStyles } from "../../styles";
import { getNextNumericId } from "../utils";
export var LogicSection = function (_a) {
    var logic = _a.logic, updateLogic = _a.updateLogic;
    var classes = useStyles();
    return (_jsxs(Paper, __assign({ classes: { root: "controlsSection" } }, { children: [_jsx("h2", { children: "Logic" }), _jsx(FormControlLabel, { className: classes.input, control: _jsx(Checkbox, { checked: !!logic, onChange: function (e) {
                        return updateLogic(e.target.checked
                            ? {
                                rules: []
                            }
                            : undefined);
                    }, name: "has-logic" }), label: "Has Logic" }), !!logic && (_jsxs(_Fragment, { children: [_jsx(RulesSection, { rules: logic.rules, updateRules: function (rules) {
                            return updateLogic(__assign(__assign({}, logic), { rules: rules }));
                        } }), _jsxs("div", { children: [logic.rules.length >= 1 ? "If no rules are matched, " : "Always ", "jump to", _jsxs(ToggleButtonGroup, __assign({ value: !isNil(logic.fallbackJumpToId), exclusive: true, onChange: function (_, val) {
                                    updateLogic(__assign(__assign({}, logic), { fallbackJumpToId: val ? "" : undefined }));
                                } }, { children: [_jsx(ToggleButton, __assign({ value: false }, { children: "Next" })), _jsx(ToggleButton, __assign({ value: true }, { children: "Specific" }))] })), "node"] }), !isNil(logic.fallbackJumpToId) && (_jsx(TextField, { label: "No Match Jump Id", variant: "outlined", value: logic.fallbackJumpToId, required: true, onChange: function (e) {
                            updateLogic(__assign(__assign({}, logic), { fallbackJumpToId: e.currentTarget.value }));
                        } }))] }))] })));
};
var RulesSection = /** @class */ (function (_super) {
    __extends(RulesSection, _super);
    function RulesSection() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onDragEnd = function (result) {
            if (!result.destination) {
                return;
            }
            var _a = _this.props, rules = _a.rules, updateRules = _a.updateRules;
            var newRules = Array.from(rules);
            var removed = newRules.splice(result.source.index, 1)[0];
            newRules.splice(result.destination.index, 0, removed);
            updateRules(newRules);
        };
        return _this;
    }
    RulesSection.prototype.render = function () {
        var _a = this.props, rules = _a.rules, updateRules = _a.updateRules;
        return (_jsxs(_Fragment, { children: [_jsx("div", { children: "Each of these rules will be checked in order, the first one which matches will be the one that is followed. Jumps to the id specified. If no rules match, jump to the next node." }), _jsx(DragDropContext, __assign({ onDragEnd: this.onDragEnd }, { children: _jsx(Droppable, __assign({ droppableId: "rules" }, { children: function (provided) { return (_jsxs("div", __assign({}, provided.droppableProps, { ref: provided.innerRef }, { children: [rules.map(function (item, index) {
                                    var id = item.id || JSON.stringify(item);
                                    return (_jsx(Draggable, __assign({ draggableId: id, index: index }, { children: function (provided) { return (_jsx(RuleControls, __assign({ rule: item, updateRule: function (rule) {
                                                return updateRules(__spreadArray(__spreadArray(__spreadArray([], rules.slice(0, index), true), [
                                                    rule
                                                ], false), rules.slice(index + 1), true));
                                            }, deleteRule: function () {
                                                updateRules(__spreadArray(__spreadArray([], rules.slice(0, index), true), rules.slice(index + 1), true));
                                            }, ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps))); } }), id));
                                }), provided.placeholder] }))); } })) })), _jsx(Button, __assign({ variant: "contained", color: "primary", onClick: function () {
                        return updateRules(__spreadArray(__spreadArray([], rules, true), [
                            {
                                id: getNextNumericId(rules).toString(),
                                conditions: [
                                    {
                                        id: "1",
                                        comparison: Comparison.Eq,
                                        comparisonValue: ""
                                    }
                                ],
                                booleanReduceFn: BooleanReduceFn.All,
                                jumpToId: ""
                            }
                        ], false));
                    } }, { children: "Add Rule" }))] }));
    };
    return RulesSection;
}(React.Component));
var RuleControls = forwardRef(function (_a, ref) {
    var rule = _a.rule, updateRule = _a.updateRule, deleteRule = _a.deleteRule, rest = __rest(_a, ["rule", "updateRule", "deleteRule"]);
    return (_jsxs(Paper, __assign({ ref: ref, classes: { root: "controlsSection" } }, rest, { children: [rule.conditions.length <= 1 ? (_jsx("div", { children: "If this condition is met" })) : (_jsxs("div", { children: ["If", _jsxs(ToggleButtonGroup, __assign({ value: rule.booleanReduceFn, exclusive: true, onChange: function (_, val) {
                            if (val) {
                                updateRule(__assign(__assign({}, rule), { booleanReduceFn: val }));
                            }
                        } }, { children: [_jsx(ToggleButton, __assign({ value: BooleanReduceFn.All }, { children: "All" })), _jsx(ToggleButton, __assign({ value: BooleanReduceFn.Any }, { children: "Any" }))] })), "of these conditions are met"] })), _jsx(ConditionsSection, { conditions: rule.conditions, updateConditions: function (conditions) {
                    return updateRule(__assign(__assign({}, rule), { conditions: conditions }));
                } }), _jsx("div", { children: "Then jump to this node:" }), _jsx(TextField, { label: "Jump To Id", variant: "outlined", value: rule.jumpToId || "", required: true, onChange: function (e) {
                    updateRule(__assign(__assign({}, rule), { jumpToId: e.currentTarget.value }));
                } }), _jsx(Button, __assign({ variant: "contained", color: "secondary", onClick: function () { return deleteRule(); } }, { children: "Delete Rule" }))] })));
});
var ConditionsSection = /** @class */ (function (_super) {
    __extends(ConditionsSection, _super);
    function ConditionsSection() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ConditionsSection.prototype.render = function () {
        var _a = this.props, conditions = _a.conditions, updateConditions = _a.updateConditions;
        return (_jsxs(_Fragment, { children: [_jsx("div", { children: conditions.map(function (item, index) {
                        var id = item.id || JSON.stringify(item);
                        return (_jsx(ConditionControls, { condition: item, updateCondition: function (condition) {
                                return updateConditions(__spreadArray(__spreadArray(__spreadArray([], conditions.slice(0, index), true), [
                                    condition
                                ], false), conditions.slice(index + 1), true));
                            }, deleteCondition: function () {
                                updateConditions(__spreadArray(__spreadArray([], conditions.slice(0, index), true), conditions.slice(index + 1), true));
                            } }, id));
                    }) }), _jsx(Button, __assign({ variant: "contained", color: "primary", onClick: function () {
                        return updateConditions(__spreadArray(__spreadArray([], conditions, true), [
                            {
                                id: getNextNumericId(conditions).toString(),
                                comparison: Comparison.Eq
                            }
                        ], false));
                    } }, { children: "Add Condition" }))] }));
    };
    return ConditionsSection;
}(React.Component));
var ConditionControls = forwardRef(function (_a, ref) {
    var condition = _a.condition, updateCondition = _a.updateCondition, deleteCondition = _a.deleteCondition, rest = __rest(_a, ["condition", "updateCondition", "deleteCondition"]);
    return (_jsxs(Paper, __assign({ ref: ref, classes: { root: "controlsSection" } }, rest, { children: [_jsxs("div", { children: ["The answer to", _jsxs(ToggleButtonGroup, __assign({ value: isNotNil(condition.sessionValueKey), exclusive: true, onChange: function (_, val) {
                            updateCondition(__assign(__assign({}, condition), { sessionValueKey: val ? "" : undefined, ifNotAnswered: undefined }));
                        } }, { children: [_jsx(ToggleButton, __assign({ value: false }, { children: "This" })), _jsx(ToggleButton, __assign({ value: true }, { children: "Other" }))] })), "question"] }), isNotNil(condition.sessionValueKey) && (_jsxs(_Fragment, { children: [_jsx(TextField, { label: "Comparison Node Id", variant: "outlined", value: condition.sessionValueKey || "", required: true, onChange: function (e) {
                            updateCondition(__assign(__assign({}, condition), { sessionValueKey: e.currentTarget.value }));
                        } }), _jsxs("p", { children: ["If the comparison node hasn't been answered this condition should be condidered:", _jsxs(ToggleButtonGroup, __assign({ value: condition.ifNotAnswered, exclusive: true, onChange: function (_, val) {
                                    updateCondition(__assign(__assign({}, condition), { ifNotAnswered: val }));
                                } }, { children: [_jsx(ToggleButton, __assign({ value: true }, { children: "True" })), _jsx(ToggleButton, __assign({ value: false }, { children: "False" }))] }))] })] })), _jsxs(FormControl, { children: [_jsx(InputLabel, __assign({ id: "comparison-select-label" }, { children: "Comparison" })), _jsx(Select, __assign({ labelId: "comparison-select-label", id: "comparison-select", value: condition.comparison, onChange: function (e) {
                            var value = e.target.value;
                            if (typeof value !== "string") {
                                throw new Error("Invalid type of " + value);
                            }
                            var comparison = value;
                            updateCondition(__assign(__assign({}, condition), { comparison: comparison }));
                        } }, { children: Object.values(Comparison).map(function (messageType) {
                            return (_jsx(MenuItem, __assign({ value: messageType }, { children: messageType }), messageType));
                        }) }))] }), _jsx(TextField, { label: "Comparison Value", variant: "outlined", value: condition.comparisonValue || "", required: true, onChange: function (e) {
                    updateCondition(__assign(__assign({}, condition), { comparisonValue: e.currentTarget.value }));
                } }), _jsx(Button, __assign({ variant: "contained", color: "secondary", onClick: function () { return deleteCondition(); } }, { children: "Delete Condition" }))] })));
});
