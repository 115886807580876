var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import z from "zod";
import { ReflectionJsonSchema, SessionPageType } from "@blueheart/conversation-graph/lib/reflections/reflectionQuestions";
import { JsonDisplay } from "../common/JsonDisplay/JsonDisplay";
import { NodesList } from "./NodeList/NodeList";
import { NodeControls } from "./NodeControls/NodeControls";
var STORAGE_KEY = "reflection";
var INITIAL_REFLECTION = [];
var INITIAL_REFLECTION_JSON = JSON.stringify(INITIAL_REFLECTION, null, 2);
export function Reflections() {
    var _a = useState(false), hasLoaded = _a[0], setHasLoaded = _a[1];
    var _b = useState(INITIAL_REFLECTION), reflection = _b[0], setReflectionRaw = _b[1];
    var _c = useState(INITIAL_REFLECTION_JSON), questionnaireJson = _c[0], setReflectionJsonRaw = _c[1];
    var _d = useState(true), isJsonValid = _d[0], setIsJsonValid = _d[1];
    var _e = useState([]), validationErrors = _e[0], setValidationErrors = _e[1];
    var _f = useState(undefined), selectedNodeIndex = _f[0], setSelectedNodeIndex = _f[1];
    var saveReflection = function (newReflectionJson) {
        localStorage.setItem(STORAGE_KEY, newReflectionJson);
    };
    var setReflection = useCallback(function (newReflection) {
        // TODO is there a better place to put this?
        var withUpdatedIsLast = newReflection
            .map(function (page) { return ("isLast" in page ? __assign(__assign({}, page), { isLast: undefined }) : page); })
            .reverse();
        var lastQuestionIndex = withUpdatedIsLast.findIndex(function (_a) {
            var type = _a.type;
            return [
                SessionPageType.INTERACTIVE,
                SessionPageType.MULTIPLE_CHOICE,
                SessionPageType.QUESTION
            ].includes(type);
        });
        if (lastQuestionIndex !== -1) {
            withUpdatedIsLast.splice(lastQuestionIndex, 1, __assign(__assign({}, withUpdatedIsLast[lastQuestionIndex]), { isLast: true }));
        }
        withUpdatedIsLast.reverse();
        var newReflectionJson = JSON.stringify(withUpdatedIsLast, null, 2);
        setReflectionRaw(newReflection);
        setReflectionJsonRaw(newReflectionJson);
        saveReflection(newReflectionJson);
        var parseResult = ReflectionJsonSchema.safeParse(withUpdatedIsLast);
        setIsJsonValid(parseResult.success);
        setValidationErrors(!parseResult.success ? parseResult.error.issues : []);
    }, []);
    var setReflectionJson = useCallback(function (newReflectionJson) {
        setReflectionJsonRaw(newReflectionJson);
        saveReflection(newReflectionJson);
        try {
            var resultReflection = JSON.parse(newReflectionJson);
            setReflectionRaw(resultReflection);
            var parseResult = ReflectionJsonSchema.safeParse(resultReflection);
            setIsJsonValid(parseResult.success);
            setValidationErrors(!parseResult.success ? parseResult.error.issues : []);
        }
        catch (err) {
            console.error(err);
            setIsJsonValid(false);
            setValidationErrors(new z.ZodError([
                {
                    code: z.ZodIssueCode.custom,
                    path: ["setReflectionJson"],
                    message: "Invalid JSON."
                }
            ]).issues);
        }
    }, []);
    useEffect(function () {
        if (!hasLoaded) {
            var saved = localStorage.getItem(STORAGE_KEY);
            if (saved) {
                setReflectionJson(saved);
            }
            setHasLoaded(true);
        }
    }, [hasLoaded, setReflectionJson]);
    useEffect(function () {
        if (typeof window === "undefined") {
            return;
        }
        window.setReflection = setReflection;
        window.setReflectionJson = setReflectionJson;
    }, [setReflection, setReflectionJson]);
    if (!hasLoaded) {
        return _jsx("div", { children: "Loading" });
    }
    return (_jsxs("div", __assign({ className: "page" }, { children: [_jsx(NodesList, { reflection: reflection, setReflection: setReflection, selectedNodeIndex: selectedNodeIndex, setSelectedNodeIndex: setSelectedNodeIndex }), _jsx(NodeControls, { reflection: reflection, setReflection: setReflection, selectedNodeIndex: selectedNodeIndex, setSelectedNodeIndex: setSelectedNodeIndex }), _jsx(JsonDisplay, { json: questionnaireJson, setJson: setReflectionJson, isJsonValid: isJsonValid, errors: validationErrors.map(function (error) {
                    return error.path.length > 0
                        ? "".concat(error.path.join("-"), ": ").concat(error.message)
                        : error.message;
                }) })] })));
}
