import { colors, colors2 } from "./colors";
// Please note that shadows on Android do not work as you'd expect
// see https://stackoverflow.com/questions/44908580/react-native-shadow-not-appearing
export var shadowStyles = {
    smallShadow: {
        elevation: 2,
        shadowColor: colors.black6th,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.6,
        shadowRadius: 3
    },
    mediumShadow: {
        shadowColor: colors2.black,
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.11,
        shadowRadius: 3.84,
        elevation: 5
    },
    mordernShadow: {
        shadowColor: colors2.black,
        shadowOpacity: 0.05,
        shadowOffset: { height: 0, width: 4 }
    },
    noShadow: {
        shadowOpacity: 0
    }
};
