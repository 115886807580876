var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import React from "react";
import { Tab, Tabs } from "@material-ui/core";
import { Reflections } from "./reflections/Reflections";
import { Conversations } from "./conversations/Conversations";
function RoutesBase(_a) {
    var history = _a.history, location = _a.location;
    return (_jsxs(_Fragment, { children: [_jsxs(Tabs, __assign({ value: location.pathname, onChange: function (_, val) { return history.push(val); } }, { children: [_jsx(Tab, { label: "Reflections", value: "/reflections" }), _jsx(Tab, { label: "Conversations", value: "/conversations" })] })), _jsxs(Switch, { children: [_jsx(Route, __assign({ exact: true, path: "/" }, { children: _jsx("span", { children: "Click one of the pages above to get started" }) })), _jsx(Route, __assign({ path: "/conversations" }, { children: _jsx(Conversations, {}) })), _jsx(Route, __assign({ path: "/reflections" }, { children: _jsx(Reflections, {}) })), _jsx(Redirect, { to: "/" })] })] }));
}
export var Routes = withRouter(RoutesBase);
