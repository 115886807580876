var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Button from "@material-ui/core/Button";
import isNil from "lodash/isNil";
import { SessionPageType } from "@blueheart/conversation-graph/lib/reflections/reflectionQuestions";
import { QuestionCard } from "./QuestionCard";
var createNewDefaultNode = function () {
    return {
        type: SessionPageType.SIMPLE,
        text: ""
    };
};
var reorder = function (list, startIndex, endIndex) {
    var result = Array.from(list);
    var removed = result.splice(startIndex, 1)[0];
    result.splice(endIndex, 0, removed);
    return result;
};
var NodesList = /** @class */ (function (_super) {
    __extends(NodesList, _super);
    function NodesList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onDragEnd = function (result) {
            if (!result.destination) {
                return;
            }
            var _a = _this.props, reflection = _a.reflection, setReflection = _a.setReflection, selectedNodeIndex = _a.selectedNodeIndex, setSelectedNodeIndex = _a.setSelectedNodeIndex;
            var nodes = reorder(reflection, result.source.index, result.destination.index);
            setReflection(nodes);
            if (!isNil(selectedNodeIndex)) {
                if (selectedNodeIndex === result.source.index) {
                    // moved selected card
                    setSelectedNodeIndex(result.destination.index);
                }
                else if (result.source.index < selectedNodeIndex &&
                    selectedNodeIndex <= result.destination.index) {
                    // before to after
                    setSelectedNodeIndex(selectedNodeIndex - 1);
                }
                else if (result.destination.index <= selectedNodeIndex &&
                    selectedNodeIndex < result.source.index) {
                    // after to before
                    setSelectedNodeIndex(selectedNodeIndex + 1);
                }
            }
        };
        _this.onAddClick = function () {
            var _a = _this.props, reflection = _a.reflection, setReflection = _a.setReflection, setSelectedNodeIndex = _a.setSelectedNodeIndex;
            setReflection(__spreadArray(__spreadArray([], (reflection || []), true), [createNewDefaultNode()], false));
            setSelectedNodeIndex(reflection.length);
        };
        _this.onAddAfterClick = function (index) {
            var _a = _this.props, reflection = _a.reflection, setReflection = _a.setReflection;
            setReflection(__spreadArray(__spreadArray(__spreadArray([], reflection.slice(0, index + 1), true), [
                createNewDefaultNode()
            ], false), reflection.slice(index + 1), true));
        };
        _this.onDeleteClick = function (index) {
            var _a = _this.props, reflection = _a.reflection, setReflection = _a.setReflection;
            setReflection(__spreadArray(__spreadArray([], reflection.slice(0, index), true), reflection.slice(index + 1), true));
        };
        return _this;
    }
    NodesList.prototype.render = function () {
        var _this = this;
        var _a = this.props, reflection = _a.reflection, selectedNodeIndex = _a.selectedNodeIndex, setSelectedNodeIndex = _a.setSelectedNodeIndex;
        return (_jsx(DragDropContext, __assign({ onDragEnd: this.onDragEnd }, { children: _jsx(Droppable, __assign({ droppableId: "droppable" }, { children: function (provided) { return (_jsxs(Grid, __assign({ classes: { root: "nodes" }, container: true, direction: "column" }, provided.droppableProps, { ref: provided.innerRef }, { children: [!reflection || reflection.length === 0 ? (_jsx("p", { children: "\"Nothing here yet, click 'Add' to get started\"" })) : (reflection.map(function (item, index) { return (_jsx(Draggable, __assign({ draggableId: "".concat(index), index: index }, { children: function (provided, snapshot) { return (_jsx(QuestionCard, __assign({ node: item, onAddAfterClick: _this.onAddAfterClick, onDeleteClick: _this.onDeleteClick, index: index, selectedNodeIndex: selectedNodeIndex, setSelectedNodeIndex: setSelectedNodeIndex, isDragging: snapshot.isDragging, ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps))); } }), index)); })), provided.placeholder, _jsx(Button, __assign({ variant: "contained", color: "primary", onClick: _this.onAddClick }, { children: "Add" }))] }))); } })) })));
    };
    return NodesList;
}(React.Component));
export { NodesList };
