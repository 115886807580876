/** @deprecated colors is old brand colours, we should use colors2 instead, see below */
export var colors = {
    white: "#ffffff",
    white50: "#ffffff7f",
    white25: "#ffffff3f",
    smoke: "#e6e6e6",
    smoke20: "#e6e6e633",
    smoke70: "#e6e6e6B3",
    offWhite: "#e4e4e4",
    silver: "#c2c4ca",
    silverPink: "#c9c5c5",
    titanium: "rgba(210, 213, 219, 0.9)",
    steel: "#4a4a4a",
    darkGrey: "#262628",
    black: "#000000",
    black30: "#0000004D",
    black50: "#0000007f",
    black75: "#000000Bf",
    blackTransparent: "#00000000",
    black6th: "rgba(0, 0, 0, 0.16)",
    grey79: "#797979",
    greyA8: "#a8a8a8",
    slate70: "#5b677eB3",
    slate50: "#5b677e7f",
    darkGrey50: "#2626287f",
    navy: "#113182",
    navyTransparent: "#11318200",
    navy50: "#1131827f",
    navy75: "#113182bf",
    deepBlue: "#0a1c3e",
    deeperBlue: "#07122f",
    deepBlueTransparent: "#0a1c3e00",
    deepBlue93: "#0a1c3e5D",
    deepBlue50: "#0a1c3e7f",
    deepBlue75: "#0a1c3eBf",
    deepBlue30: "#0a1c3e4c",
    powderBlue: "#a5bdfa",
    powderBlue70: "#a5bdfaB3",
    powderBlue50: "#a5bdfa7F",
    cornflowerBlue: "#384c90",
    greyishBlue: "#273c63",
    pigPink: "#f193b8",
    pigPink40: "#f193b866",
    pigPink20: "#f193b833",
    green: "#a1f193",
    lime: "#00de71",
    greenish: "#45a767",
    greenish50: "#45a7677f",
    aquamarine: "#4bd0c6",
    rose: "#ff0064",
    cinnabar: "#ea646a",
    sand: "#eabc64",
    transparent: "rgba(0,0,0,0)",
    facebookBlue: "#4267b2"
};
// new colour palette designed to replace original colours
// see https://www.figma.com/file/jDvm320YimtjsGS55sDCTS/Blueheart?node-id=22%3A1274 for correct names
// see https://www.figma.com/file/tp8gp1xRUfuxOiXSz4SPp3/Blueheart-New-Visual-System?node-id=31%3A2 for original inspiration
/**
 * @deprecated prefer to use the colors or palettes from the design system
 * @example
 * import { colors, palettes } from "@blueheart/ui-library/src/designSystem";
 */
export var colors2 = {
    // primary
    deepBlue: "#07104E",
    steelBlue: "#38416D",
    lightBlue: "#DCE1FE",
    /** primary cta */
    royalBlue: "#4754F0",
    // neutral
    linen: "#F9EFE5",
    lightLinen: "#FFF8F5",
    white: "#FFFFFF",
    offBlack: "#0D0B22",
    silver: "#c2c4ca",
    steel: "#4a4a4a",
    black: "#000000",
    lightGrey: "#C2C5D1",
    tertiaryText: "#8489A4",
    gallery: "#ebebeb",
    // accent
    marshmallow: "#FED6F0",
    cranberry: "#DB576D",
    strawberry: "#FF5A4E",
    purplishRed: "#D8329E",
    apricot: "#FF9D6C",
    peach: "#FFCFB7",
    fadedSalmon: "#FD8563",
    lavender: "#AE94FB",
    lavenderTransparent: "#AE94FB50",
    lavenderBlue: "#bbc5ff",
    lightLavender: "#d8cbff",
    lilac: "#CDBEFF",
    lightLilac: "#E7E0FF",
    lynch: "#6b7398",
    purple: "#7E5BED",
    whiteTranslucent: "#FFFFFF60",
    deepBlueTransparent: "rgba(7, 16, 78, 0.2)",
    // semantic
    error: "#FF3B30",
    success: "#3CCE4D",
    warning: "#FF9500",
    // background
    beige: "#FFFAF7",
    seashell: "#FFF8F4",
    fantasy: "#faf3ef",
    zumthor: "#e8efff",
    serenade: "#fff7eb",
    citrineWhite: "#f8e8d2",
    linkWater: "#f3f4fc",
    soapstone: "#fffbf9",
    solitude: "#e0eaff",
    provicialPink: "#fdf0e9",
    peachOrange: "#ffbe99",
    romantic: "#ffceb2",
    titanWhite: "#f3efff",
    selago: "#f1f1fd",
    coral: "#ff8652",
    // variants
    midnightBlue: "#202A6D",
    // strokes and dividers
    darkBlue70: "#283383",
    // brands
    facebookBlue: "#4267b2",
    // utility
    transparent: "#00000000"
};
export var SESSION_OVERLAY_DEFAULT_COLOR = colors.black30;
export var SESSION_OVERLAY_LOCKED_COLOR = colors.powderBlue70;
