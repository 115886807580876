// For some examples of font sizes https://material.io/design/typography/#
export var fontSizes = {
    overSized: 40,
    titleHeavy: 32,
    title1: 24,
    title2: 22,
    headline: 18,
    caption: 10,
    primary: 16,
    secondary: 14,
    tertiary: 13,
    micro: 12,
    // the font sizes below are all deprecated and should not be used in new code
    // use the ones above instead
    /** @deprecated */
    xs2: 10,
    /** @deprecated */
    xs: 12,
    /** @deprecated */
    s: 14,
    /** @deprecated */
    m: 16,
    /** @deprecated */
    l: 18,
    /** @deprecated */
    xl: 24,
    /** @deprecated */
    xlphi: 28,
    /** @deprecated */
    xl2: 32,
    /** @deprecated */
    xle: 36,
    /** @deprecated */
    xl3: 48,
    /** @deprecated */
    xl4: 60,
    /** @deprecated */
    xl5: 96,
    /** @deprecated */
    xl7: 180
};
export var lineHeights = {
    overSized: 54,
    titleHeavy: 40,
    title1: 31,
    title2: 28,
    headline: 25,
    caption: 15,
    primary: 24,
    secondary: 21,
    tertiary: 20,
    micro: 18
};
export var spacing = {
    xs2: 3,
    xs: 5,
    s: 10,
    m: 20,
    l: 30,
    xl: 40
};
export var borderRadius = {
    s: 4,
    m: 7,
    l: 13,
    xl: 18
};
export var layoutSizes = {
    sidePadding: 25,
    sidePaddingNarrow: 16,
    scrollSpacing: 15
};
export var SMALL_THUMBNAIL_WIDTH = 150;
export var SMALL_THUMBNAIL_HEIGHT = 150;
export var LARGE_HERO_WIDTH = 480;
export var LARGE_HERO_HEIGHT = Math.ceil((LARGE_HERO_WIDTH * 10) / 9);
export var SMALL_HERO_WIDTH = 480;
export var SMALL_HERO_HEIGHT = Math.ceil(SMALL_HERO_WIDTH * 0.4);
export var SESSION_THUMBNAIL_ROUND_SIZE = 64;
export var LOGIN_BUTTON_HEIGHT = 52;
export var PHONE_WIDTH_BREAKPOINT = 480;
export var LARGE_SCREEN_MEDIA_QUERY = "min-width: ".concat(PHONE_WIDTH_BREAKPOINT + 0.001, "px");
