"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.updateSessionData = exports.getUserResponseValue = exports.SideEffect = exports.ReduceFn = exports.MessageType = exports.ImageSelectShape = exports.HealthTextResponse = exports.HealthScore = exports.ConversationGraphZ = exports.Comparison = exports.BooleanReduceFn = void 0;
var assertNever_1 = require("@blueheart/utils/src/assertNever");
var booleanReduceFn_1 = require("./parsers/logic/booleanReduceFn");
exports.BooleanReduceFn = booleanReduceFn_1.BooleanReduceFn;
var comparison_1 = require("./parsers/logic/comparison");
exports.Comparison = comparison_1.Comparison;
var conversationGraph_1 = require("./parsers/conversationGraph");
exports.ConversationGraphZ = conversationGraph_1.ConversationGraphZ;
var healthScore_1 = require("./parsers/healthScore");
exports.HealthScore = healthScore_1.HealthScore;
var healthTextResponse_1 = require("./parsers/healthTextResponse");
exports.HealthTextResponse = healthTextResponse_1.HealthTextResponse;
var imageSelectShape_1 = require("./parsers/imageSelectShape");
exports.ImageSelectShape = imageSelectShape_1.ImageSelectShape;
var messageType_1 = require("./parsers/messageType");
exports.MessageType = messageType_1.MessageType;
var reduceFn_1 = require("./parsers/logic/reduceFn");
exports.ReduceFn = reduceFn_1.ReduceFn;
var sideEffect_1 = require("./parsers/sideEffect");
exports.SideEffect = sideEffect_1.SideEffect;
var getUserResponseValue = function (userResponse) {
    switch (userResponse.type) {
        case messageType_1.MessageType.STATEMENT:
        case messageType_1.MessageType.ANIMATION:
            return undefined;
        case messageType_1.MessageType.YES_NO:
            return userResponse.booleanResponse;
        case messageType_1.MessageType.SINGLE_SELECT:
            return userResponse.singleChoiceResponse.value;
        case messageType_1.MessageType.MULTIPLE_SELECT:
            return userResponse.multipleChoiceResponses.map(function (option) { return option.value; });
        case messageType_1.MessageType.SINGLE_SELECT_IMAGE:
            return userResponse.singleChoiceImageResponse.value;
        case messageType_1.MessageType.MULTIPLE_SELECT_IMAGE:
            return userResponse.multipleChoiceImageResponses.map(function (option) { return option.value; });
        case messageType_1.MessageType.NUMBER:
            return userResponse.numericResponse;
        case messageType_1.MessageType.SHORT_TEXT:
            return userResponse.shortTextResponse;
        case messageType_1.MessageType.LONG_TEXT:
            return userResponse.longTextResponse;
        case messageType_1.MessageType.EMAIL:
            return userResponse.emailResponse;
        case messageType_1.MessageType.PHONE_NUMBER:
            return userResponse.phoneNumberResponse;
        case messageType_1.MessageType.ORDERING:
            return userResponse.orderedResponses.map(function (_a) {
                var value = _a.value;
                return value;
            });
        case messageType_1.MessageType.ORDERING_IMAGE:
            return userResponse.orderedImageResponses.map(function (_a) {
                var value = _a.value;
                return value;
            });
        case messageType_1.MessageType.LIKERT:
            return userResponse.numericLikertResponse;
        default:
            throw (0, assertNever_1.assertNever)(userResponse);
    }
};
exports.getUserResponseValue = getUserResponseValue;
var updateSessionData = function (sessionData, messageWithResponse) {
    var _a;
    return (__assign(__assign({}, sessionData), (_a = {}, _a[messageWithResponse.message.id] = (0, exports.getUserResponseValue)(messageWithResponse.response), _a)));
};
exports.updateSessionData = updateSessionData;
