var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { JsonDisplay } from "../common/JsonDisplay/JsonDisplay";
import { NodesList } from "./NodeList/NodeList";
import { NodeControls } from "./NodeControls/NodeControls";
var STORAGE_KEY = "conversation";
var INITIAL_QUESTIONNAIRE = {
    nodes: [],
    data: {}
};
var INITIAL_QUESTIONNAIRE_JSON = JSON.stringify(INITIAL_QUESTIONNAIRE, null, 2);
export function Conversations() {
    var _a = useState(false), hasLoaded = _a[0], setHasLoaded = _a[1];
    var _b = useState(INITIAL_QUESTIONNAIRE), questionnaire = _b[0], setQuestionnaireRaw = _b[1];
    var _c = useState(INITIAL_QUESTIONNAIRE_JSON), questionnaireJson = _c[0], setQuestionnaireJsonRaw = _c[1];
    var _d = useState(true), isJsonValid = _d[0], setIsJsonValid = _d[1];
    var _e = useState(undefined), selectedNodeIndex = _e[0], setSelectedNodeIndex = _e[1];
    var saveQuestionnaire = function (newQuestionnaireJson) {
        localStorage.setItem(STORAGE_KEY, newQuestionnaireJson);
    };
    var setQuestionnaire = useCallback(function (newQuestionnaire) {
        var newQuestionnaireJson = JSON.stringify(newQuestionnaire, null, 2);
        setQuestionnaireRaw(newQuestionnaire);
        setQuestionnaireJsonRaw(newQuestionnaireJson);
        saveQuestionnaire(newQuestionnaireJson);
        setIsJsonValid(true);
    }, []);
    var setQuestionnaireJson = useCallback(function (newQuestionnaireJson) {
        setQuestionnaireJsonRaw(newQuestionnaireJson);
        saveQuestionnaire(newQuestionnaireJson);
        try {
            setQuestionnaireRaw(JSON.parse(newQuestionnaireJson));
            setIsJsonValid(true);
        }
        catch (err) {
            console.error(err);
            setIsJsonValid(false);
        }
    }, []);
    useEffect(function () {
        if (!hasLoaded) {
            var saved = localStorage.getItem(STORAGE_KEY);
            if (saved) {
                setQuestionnaireJson(saved);
            }
            setHasLoaded(true);
        }
    }, [hasLoaded, setQuestionnaireJson]);
    useEffect(function () {
        if (typeof window === "undefined") {
            return;
        }
        window.setQuestionnaire = setQuestionnaire;
        window.setQuestionnaireJson = setQuestionnaireJson;
    }, [setQuestionnaire, setQuestionnaireJson]);
    if (!hasLoaded) {
        return _jsx("div", { children: "Loading" });
    }
    return (_jsxs("div", __assign({ className: "page" }, { children: [_jsx(NodesList, { conversationGraph: questionnaire, setConversationGraph: setQuestionnaire, selectedNodeIndex: selectedNodeIndex, setSelectedNodeIndex: setSelectedNodeIndex }), _jsx(NodeControls, { conversationGraph: questionnaire, setConversationGraph: setQuestionnaire, selectedNodeIndex: selectedNodeIndex, setSelectedNodeIndex: setSelectedNodeIndex }), _jsx(JsonDisplay, { json: questionnaireJson, setJson: setQuestionnaireJson, isJsonValid: isJsonValid })] })));
}
